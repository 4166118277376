import { Injectable } from '@angular/core';
import { environment } from './../environment';

@Injectable()
export class ConfigService {

  constructor() { }


  getValueByKey(container, key) {

    let found = "";

    if (key != null && typeof key != "undefined") {
      container.forEach(e => {
        if (e.key.toString().toLowerCase() == key.toString().toLowerCase())
          return found = e.value;
      });
    }

    return found;
  }

  sliceAndSortArray(container) {

    return container.slice().sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });
  }



  /*

    _____ _   _ _______ ______ _____  _   _          _         ____  _____ _______ _____ ____  _   _  _____
   |_   _| \ | |__   __|  ____|  __ \| \ | |   /\   | |       / __ \|  __ \__   __|_   _/ __ \| \ | |/ ____|
     | | |  \| |  | |  | |__  | |__) |  \| |  /  \  | |      | |  | | |__) | | |    | || |  | |  \| | (___
     | | | . ` |  | |  |  __| |  _  /| . ` | / /\ \ | |      | |  | |  ___/  | |    | || |  | | . ` |\___ \
    _| |_| |\  |  | |  | |____| | \ \| |\  |/ ____ \| |____  | |__| | |      | |   _| || |__| | |\  |____) |
   |_____|_| \_|  |_|  |______|_|  \_\_| \_/_/    \_\______|  \____/|_|      |_|  |_____\____/|_| \_|_____/



  */


  public get blobBasePath() {
    if (environment.production)
      return "https://gastonapp.blob.core.windows.net/master/";
    else
      return "https://gastonapp.blob.core.windows.net/development/";
  }



  //	Array con i nomi delle aziende che operano sul territorio
  private _areaExpertise =
    [
      {
        key: '1',
        value: 'Educo Sud'
      },
      {
        key: '2',
        value: 'Educo Sanremo'
      },
      {
        key: '3',
        value: 'Educo Triveneto'
      },
      {
        key: '4',
        value: 'Educo Sicilia'
      },
      {
        key: '5',
        value: 'Educo Emilia Romagna'
      },
      {
        key: '6',
        value: 'Educo Lazio'
      },
      {
        key: '7',
        value: 'Educo Lombardia'
      }
    ];


  public get areaExpertiseSelect() {
    return this.sliceAndSortArray(this._areaExpertise);
  }

  public getAreaExpertiseValueByKey(key: any) {
    return this.getValueByKey(this._areaExpertise, key);
  }






  //	Array con le tipologie di pagamenti di un tour del theatrino
  private _groupCompanies =
    [
      {
        key: '2',
        value: 'Educo'
      },
      {
        key: '3',
        value: 'Educo-Persona'
      }
    ];

  public get groupCompaniesSelect() {
    return this.sliceAndSortArray(this._groupCompanies);
  }

  public getGroupCompanyValueByKey(key: any) {
    return this.getValueByKey(this._groupCompanies, key);
  }








  /*

    _______ _____ __  __ ______     ____  _____ _______ _____ ____  _   _  _____
   |__   __|_   _|  \/  |  ____|   / __ \|  __ \__   __|_   _/ __ \| \ | |/ ____|
    | |    | | | \  / | |__     | |  | | |__) | | |    | || |  | |  \| | (___
    | |    | | | |\/| |  __|    | |  | |  ___/  | |    | || |  | | . ` |\___ \
    | |   _| |_| |  | | |____   | |__| | |      | |   _| || |__| | |\  |____) |
    |_|  |_____|_|  |_|______|   \____/|_|      |_|  |_____\____/|_| \_|_____/

    */



  private timeCalculator(startTime, endTime, interval) {
    var times = []; // array dei tempi

    var x = interval; //	intervallo in minuti
    var tt = startTime * 60; // start time
    var et = endTime * 60; //	end time

    for (var i = 0; tt <= et; i++) {
      var hh = Math.floor(tt / 60); // orario del giorno in formato 0-24
      var mm = (tt % 60); // minuti in 1 ora in formato 0-55
      times[i] = ("0" + (hh % 24)).slice(-2) + ':' + ("0" + mm).slice(-2);
      tt = tt + x;
    }

    return times;
  }


  //	Seleziona un intervallo di tempo predefinito per i camp che va dalle 8 alle 22 ogni 30 minuti
  public get timeCampPlanSelect() {

    var arrayFormatted = [];
    var times = this.timeCalculator(7, 22, 30);

    for (var i = 0; i < times.length; i++)
      arrayFormatted[i] = { id: i, value: times[i] };

    return arrayFormatted;
  }

  //	Seleziona un intervallo di tempo predefinito per i corsi di formazione che va dalle 8 alle 22 ogni 30 minuti
  public get timeCourseSchedulePlanSelect() {

    var arrayFormatted = [];
    var times = this.timeCalculator(7, 22, 30);

    for (var i = 0; i < times.length; i++)
      arrayFormatted[i] = { id: i, value: times[i] };

    return arrayFormatted;
  }

  //	Seleziona un intervallo di tempo predefinito per i tour che va dalle 7 alle 22 ogni 5 minuti
  public get timeTourSelect() {

    var arrayFormatted = [];
    var times = this.timeCalculator(7, 22, 5);

    for (var i = 0; i < times.length; i++)
      arrayFormatted[i] = { id: i, value: times[i] };

    return arrayFormatted;
  }

  //	Seleziona un intervallo di tempo predefinito per i reminder che va dalle 8 alle 22 ogni 30 minuti
  public get timeReminderSelect() {

    var arrayFormatted = [];
    var times = this.timeCalculator(7, 22, 30);

    for (var i = 0; i < times.length; i++)
      arrayFormatted[i] = { id: i, value: times[i] };

    return arrayFormatted;
  }



  //	Seleziona un intervallo di anni da oggi fino ad uno step prefissato
  public yearSelect(yearStepBack = 10, yearStepForward = 1) {

    //	Inizializzo l'array
    var arrayOfYear: number[] = [];

    let currentYear = new Date().getFullYear();

    //	Ciclo fino al numero di annu richiesto dai parametri
    for (var i = 0; i < Math.abs(yearStepForward); i++) {
      var newdate = currentYear + yearStepForward - i;

      arrayOfYear.push(newdate);
    }

    //	Inserisco l'anno corrente
    arrayOfYear.push(currentYear);

    //	Ciclo fino al numero di annu richiesto dai parametri
    for (var i = yearStepForward; i < Math.abs(yearStepBack); i++) {
      var newdate = arrayOfYear[i] + 1;
      if (yearStepBack < 0)
        newdate = arrayOfYear[i] - 1;

      arrayOfYear.push(newdate);
    }

    return arrayOfYear
  }

  public monthSelect() {

    let month = new Array();
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    return month;
  }















  /*
    _____  ______ _____   _____  ____  _   _    ____  _____ _______ _____ ____  _   _  _____
   |  __ \|  ____|  __ \ / ____|/ __ \| \ | |  / __ \|  __ \__   __|_   _/ __ \| \ | |/ ____|
   | |__) | |__  | |__) | (___ | |  | |  \| | | |  | | |__) | | |    | || |  | |  \| | (___
   |  ___/|  __| |  _  / \___ \| |  | | . ` | | |  | |  ___/  | |    | || |  | | . ` |\___ \
   | |    | |____| | \ \ ____) | |__| | |\  | | |__| | |      | |   _| || |__| | |\  |____) |
   |_|    |______|_|  \_\_____/ \____/|_| \_|  \____/|_|      |_|  |_____\____/|_| \_|_____/
  */

  //	Array con le opzioni del gender
  private _genderPersonSelect =
    [
      {
        key: 'm',
        value: 'Male'
      },
      {
        key: 'f',
        value: 'Female'
      },
      {
        key: 'c',
        value: 'Custom'
      }
    ];

  public get genderPersonSelect() {
    return this.sliceAndSortArray(this._genderPersonSelect);
  }

  public getGenderPersonValueByKey(key: any) {
    return this.getValueByKey(this._genderPersonSelect, key);
  }


  //	Array con le taglie delle magliette
  private _shirtSizePersonSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: 'a4',
        value: 'Anni 4'
      },
      {
        key: 'a6',
        value: 'Anni 6'
      },
      {
        key: 'a8',
        value: 'Anni 8'
      },
      {
        key: 'a10',
        value: 'Anni 10'
      },
      {
        key: 'a12',
        value: 'Anni 12'
      },
      {
        key: 'XS',
        value: 'XS'
      },
      {
        key: 'S',
        value: 'S'
      },
      {
        key: 'M',
        value: 'M'
      },
      {
        key: 'L',
        value: 'L'
      },
      {
        key: 'XL',
        value: 'XL'
      },
      {
        key: 'XXL',
        value: 'XXL'
      },
      {
        key: '5',
        value: 'Child 5-6 - (non selezionabile)'
      },
      {
        key: '7',
        value: 'Child 7-8 - (non selezionabile)'
      },
      {
        key: '9',
        value: 'Child 9-11 - (non selezionabile)'
      },
      {
        key: '12',
        value: 'Child 12-14 - (non selezionabile)'
      },
      {
        key: '15',
        value: 'Child 15-16 - (non selezionabile)'
      },

    ];

  public get shirtSizePersonSelect() {
    return this._shirtSizePersonSelect.slice();
  }


  public getShirtSizePersonValueByKey(key: any) {
    return this.getValueByKey(this._shirtSizePersonSelect, key);
  }







  //	Array con le tipologie di lavoro di un iscritto ai corsi di formazione
  private _personWorkSelect =
    [
      {
        key: '1',
        value: 'Docente'
      },
      {
        key: '2',
        value: 'Altro'
      }
    ];

  public get personWorkSelect() {
    return this.sliceAndSortArray(this._personWorkSelect);
  }

  public getPersonWorkValueByKey(key: any) {
    return this.getValueByKey(this._personWorkSelect, key);
  }




  //	array con le scuole dove insegnano gli iscritti ai corsi di formazione
  private _personWorkSchoolTypeSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Infanzia'
      },
      {
        key: '2',
        value: 'Primaria'
      },
      {
        key: '3',
        value: 'Secondaria I grado'
      },
      {
        key: '4',
        value: 'Secondaria II grado'
      }
    ];

  public get personWorkSchoolTypeSelect() {
    return this.sliceAndSortArray(this._personWorkSchoolTypeSelect);
  }

  public getpersonWorkSchoolTypeValueByKey(key: any) {
    return this.getValueByKey(this._personWorkSchoolTypeSelect, key);
  }




  //	Array con i ruoli delle persone
  private _rolePersonSelect =
    [
      {
        key: '1',
        value: 'Docente',
        default_permission: []
      },
      {
        key: '2',
        value: 'Genitore',
        default_permission: []
      },
      {
        key: '3',
        value: 'Studente',
        default_permission: []
      },
      {
        key: '4',
        value: 'Camp Staff',
        default_permission: [
          "camp_read", "camp_edit_staff", "camp_edit_subscriber", "camp_edit_group", "camp_add_discount", "camp_groups_export", "camp_export", "camp_subscribers_export",
        ]
      },
      {
        key: '5',
        value: 'Dirigente',
        default_permission: []
      },
      {
        key: '6',
        value: 'Attore',
        default_permission: [
          // "actorgroup_read", "actorgroup_edit",
          "actorgroup_cashflow_edit",
        ]
      },
      {
        key: '7',
        value: 'Legale Rappresentante',
        default_permission: []
      },
      {
        key: '8',
        value: 'Contatto Amministrativo',
        default_permission: []
      },
      {
        key: '9',
        value: 'Tutor',
        default_permission: [
          "person_summary_export"
        ]
      },
      {
        key: '10',
        value: 'Office Sales Staff',
        default_permission: [
          "role_edit_1",
          "role_edit_2",
          "role_edit_3",
          "role_edit_4",
          "role_edit_5",
          "role_edit_6",
          "role_edit_7",
          "role_edit_8",
          "role_edit_9",
          "role_edit_10",
          "role_edit_12",
          "agenda_read",
          "camp_create", "camp_edit", "camp_edit_staff", "camp_edit_subscriber", "camp_edit_group", "camp_read", "camp_edit_quote", "camp_add_discount", "camp_groups_export", "camp_export", "camp_subscribers_export",
          "camp_calendar_read", "camp_calendar_edit", "camp_calendar_export", "camp_edit_signup",
          "camp_transfer_details_read", "camp_transfer_details_edit",
          "course_schedule_read", "course_schedule_create", "course_schedule_edit", "course_schedule_edit_quote", "course_schedule_add_discount", "course_schedule_export", "course_schedule_edit_group",
          "person_create", "person_role_edit", "person_role_edit_permission", "person_edit", "person_read",
          "enterprise_create", "enterprise_edit", "enterprise_read",
          "primanota_edit", "prima_nota_delete",
          "reminder_edit",
          // "vehicle_create", "vehicle_edit", "vehicle_read", "vehicle_export",
          "theatrino_read", "theatrino_edit",
          "actorgroup_read", "actorgroup_edit",
          "actorgroup_cashflow_edit",
          "show_read", "show_edit",
          // "warehouse_read", "warehouse_edit",
          // "warehouse_shipments_read", "warehouse_shipments_edit",
          // "application_tutors_read", "application_tutors_edit",
          // "application_actors_read", "application_actors_edit",
          // "application_facilitators_read", "application_facilitators_edit",
          "course_read", "course_edit",
        ]
      },
      {
        key: '11',
        value: 'Office Financial Staff',
        default_permission: [
          "agenda_read",
          "admin_reports_read", "admin_reports_export",
          "person_create", "person_role_edit", "person_role_edit_permission", "person_edit", "person_read", "person_summary_export", "person_export",
          "enterprise_create", "enterprise_edit", "enterprise_read", "enterprise_export",
          "primanota_edit", "prima_nota_delete", "prima_nota_export", "prima_nota_revisions",
          "reminder_edit",
          "vehicle_create", "vehicle_edit", "vehicle_read", "vehicle_export", "vehicle_revisions",
          "camp_create", "camp_edit", "camp_edit_staff", "camp_edit_subscriber", "camp_edit_group", "camp_read", "camp_edit_quote", "camp_add_discount", "camp_groups_export", "camp_export", "camp_subscribers_export",
          "camp_calendar_read", "camp_calendar_edit", "camp_calendar_export", "camp_edit_signup",
          "camp_transfer_details_read", "camp_transfer_details_edit",
          "camp_documents_read", "camp_documents_edit",
          "camp_reports_read", "camp_reports_export",
          "theatrino_read", "theatrino_edit", "theatrino_export",
          "theatrino_reports_read", "theatrino_reports_export",
          "actorgroup_read", "actorgroup_edit",
          "actorgroup_cashflow_edit",
          "show_read", "show_edit",
          "warehouse_read", "warehouse_edit",
          "warehouse_shipments_read", "warehouse_shipments_edit",
          "application_tutors_read", "application_tutors_edit",
          "application_actors_read", "application_actors_edit",
          "application_facilitators_read", "application_facilitators_edit",
          "course_read", "course_edit",
          "course_schedule_read", "course_schedule_create", "course_schedule_edit", "course_schedule_edit_quote", "course_schedule_edit_group", "course_schedule_add_discount", "course_schedule_export",
          "role_edit_1",
          "role_edit_2",
          "role_edit_3",
          "role_edit_4",
          "role_edit_5",
          "role_edit_6",
          "role_edit_7",
          "role_edit_8",
          "role_edit_9",
          "role_edit_10",
          "role_edit_11",
          "role_edit_12",
          "role_edit_13",
          "role_edit_14",
          "role_edit_15",
          "role_edit_16",
        ]
      },
      {
        key: '12',
        value: 'Facilitator',
        default_permission: []
      },
      {
        key: '13',
        value: 'Office Assistant Financial Staff',
        default_permission: [
          "role_edit_1",
          "role_edit_2",
          "role_edit_3",
          "role_edit_4",
          "role_edit_5",
          "role_edit_6",
          "role_edit_7",
          "role_edit_8",
          "role_edit_9",
          "role_edit_10",
          "role_edit_12",
          "agenda_read",
          "camp_create", "camp_edit", "camp_edit_staff", "camp_edit_subscriber", "camp_edit_group", "camp_read", "camp_edit_quote", "camp_add_discount", "camp_groups_export", "camp_export", "camp_subscribers_export",
          "camp_calendar_read", "camp_calendar_edit", "camp_calendar_export",
          "camp_transfer_details_read", "camp_edit_signup",
          "course_schedule_read", "course_schedule_create", "course_schedule_edit", "course_schedule_edit_quote", "course_schedule_edit_group", "course_schedule_add_discount", "course_schedule_export",
          "person_create", "person_role_edit", "person_role_edit_permission", "person_edit", "person_read", "person_summary_export", "person_export",
          "enterprise_create", "enterprise_edit", "enterprise_read", "enterprise_export",
          "primanota_edit", "prima_nota_delete",
          "reminder_edit",
          "vehicle_create", "vehicle_edit", "vehicle_read", "vehicle_export",
          "theatrino_read", "theatrino_edit", "theatrino_export",
          "actorgroup_read", "actorgroup_edit",
          "actorgroup_cashflow_edit",
          "show_read", "show_edit",
          // "warehouse_read", "warehouse_edit",
          // "warehouse_shipments_read", "warehouse_shipments_edit",
          // "application_tutors_read", "application_tutors_edit",
          // "application_actors_read", "application_actors_edit",
          // "application_facilitators_read", "application_facilitators_edit",
          "course_read", "course_edit",
        ]
      },
      {
        key: '14',
        value: 'Office Shipment Staff',
        default_permission: [
          "agenda_read",
          "camp_create", "camp_edit", "camp_edit_staff", "camp_edit_subscriber", "camp_edit_group", "camp_read", "camp_edit_quote", "camp_add_discount",
          "camp_calendar_read", "camp_edit_signup",
          "course_schedule_read", "course_schedule_create", "course_schedule_edit", "course_schedule_edit_quote", "course_schedule_edit_group", "course_schedule_add_discount",
          "person_read",
          "enterprise_read",
          "primanota_edit", "prima_nota_delete",
          "reminder_edit",
          "vehicle_read",
          "theatrino_read",
          "actorgroup_read", "actorgroup_edit",
          "actorgroup_cashflow_edit",
          "show_read",
          "warehouse_read", "warehouse_edit",
          "warehouse_shipments_read", "warehouse_shipments_edit",
          // "application_tutors_read", "application_tutors_edit",
          // "application_actors_read", "application_actors_edit",
          // "application_facilitators_read", "application_facilitators_edit",
          "course_read", //"course_edit",
          // "course_schedule_read", "course_schedule_edit",
        ]
      },
      {
        key: '15',
        value: 'Office English Staff',
        default_permission: [
          "role_edit_1",
          "role_edit_2",
          "role_edit_3",
          "role_edit_4",
          "role_edit_5",
          "role_edit_6",
          "role_edit_7",
          "role_edit_8",
          "role_edit_9",
          "role_edit_10",
          "role_edit_12",
          "agenda_read",
          "camp_read", //"camp_create", "camp_edit", "camp_edit_staff", "camp_edit_subscriber", "camp_edit_group", "camp_edit_quote", "camp_add_discount",
          "camp_calendar_read", "camp_calendar_edit",
          "camp_transfer_details_read", "camp_transfer_details_edit",
          "course_schedule_read", "course_schedule_create", "course_schedule_edit", "course_schedule_edit_quote", "course_schedule_add_discount", "course_schedule_edit_group",
          "person_create", "person_role_edit", "person_role_edit_permission", "person_edit", "person_read",
          "enterprise_read", //"enterprise_create", "enterprise_edit",
          "primanota_edit", "prima_nota_delete",
          "reminder_edit",
          // "vehicle_create", "vehicle_edit", "vehicle_read",
          "theatrino_read", "theatrino_edit",
          "actorgroup_read", //"actorgroup_edit",
          "actorgroup_cashflow_edit",
          "show_read", "show_edit",
          "warehouse_read", //"warehouse_edit",
          // "warehouse_shipments_read", "warehouse_shipments_edit",
          "application_tutors_read", "application_tutors_edit",
          "application_actors_read", "application_actors_edit",
          "application_facilitators_read", "application_facilitators_edit",
          // "course_read", "course_edit",
          "course_schedule_read", //"course_schedule_edit", "course_schedule_export",
        ]
      },
    ];

  public get rolePersonSelect() {
    return this.sliceAndSortArray(this._rolePersonSelect);
  }

  public getRolePersonValueByKey(key: any) {
    return this.getValueByKey(this._rolePersonSelect, key);
  }

  public getDefaultRolePermissionByKey(key: any) {

    let found = [];

    this._rolePersonSelect.forEach(e => {
      if (e.key == key)
        return found = e.default_permission;
    });

    return found;
  }



  //	Array con i tipi di indirizzi presenti
  private _addressPersonSelect =
    [
      {
        key: '1',
        value: 'Home Address'
      },
      {
        key: '2',
        value: 'Postal Address'
      }
    ];

  public get addressPersonSelect() {
    return this.sliceAndSortArray(this._addressPersonSelect);
  }

  public getAddressPersonValueByKey(key: any) {
    return this.getValueByKey(this._addressPersonSelect, key);
  }

  public convertAddressAsStringForTable(data) {

    let address = "";
    if (data.locality)
      address += data.locality + ", ";
    if (data.administrative_area_level_3_short_version)
      address += data.administrative_area_level_3_short_version;
    if (data.administrative_area_level_2_short_version && data.administrative_area_level_1_long_version)
      address += " (" + data.administrative_area_level_2_short_version + ", " + data.administrative_area_level_1_long_version + ")";
    if (data.route)
      address += " " + data.route;
    if (data.street_number)
      address += ", " + data.street_number;
    if (data.postal_code)
      address += " - " + data.postal_code;
    if (data.country_long_version)
      address += " " + data.country_long_version;

    return address;
  }





  //	Array con le tipologie di contatti
  private _contactPersonSelect =
    [
      {
        key: '1',
        value: 'Email'
      },
      {
        key: '2',
        value: 'Fax'
      },
      {
        key: '3',
        value: 'Emergency'
      },
      {
        key: '4',
        value: 'Home phone'
      },
      {
        key: '5',
        value: 'Mobile phone'
      },
      {
        key: '6',
        value: 'Parents phone'
      },
      {
        key: '7',
        value: 'Work phone'
      },
      {
        key: '8',
        value: 'Web address'
      }
    ];

  public get contactPersonSelect() {
    return this.sliceAndSortArray(this._contactPersonSelect);
  }

  public getContactPersonValueByKey(key: any) {
    return this.getValueByKey(this._contactPersonSelect, key);
  }



  //	Array con le tipologie di documenti
  private _documentPersonSelect =
    [
      {
        key: '1',
        value: 'Driver License'
      },
      {
        key: '2',
        value: 'Insurance'
      },
      {
        key: '3',
        value: 'Partita Iva'
      },
      {
        key: '4',
        value: 'Passport'
      },
      {
        key: '5',
        value: 'Social Security'
      },
      {
        key: '6',
        value: 'Working visa'
      },
      {
        key: '7',
        value: 'Curriculum Vita'
      },
      {
        key: '8',
        value: 'International Child Protection Certificate'
      }
    ];

  public get documentPersonSelect() {
    return this.sliceAndSortArray(this._documentPersonSelect);
  }

  public getDocumentPersonValueByKey(key: any) {
    return this.getValueByKey(this._documentPersonSelect, key);
  }




  //	Array con le tipologie di note del diario
  private _diaryPersonSelect =
    [
      {
        key: '1',
        value: 'Nota generica'
      },
      {
        key: '2',
        value: 'Problema'
      },
      {
        key: '3',
        value: 'Preventivo'
      }
    ];

  public get diaryPersonSelect() {
    return this.sliceAndSortArray(this._diaryPersonSelect);
  }

  public getDiaryPersonValueByKey(key: any) {
    return this.getValueByKey(this._diaryPersonSelect, key);
  }




  //	Array con le tipologie di account pagamenti
  private _paymentPersonSelect =
    [
      {
        key: '1',
        value: 'Bank Account'
      },
      {
        key: '2',
        value: 'Paypal'
      }
    ];

  public get paymentPersonSelect() {
    return this.sliceAndSortArray(this._paymentPersonSelect);
  }

  public getPaymentPersonValueByKey(key: any) {
    return this.getValueByKey(this._paymentPersonSelect, key);
  }


















  /*
    ______ _   _ _______ ______ _____  _____  _____  _____  _____ ______    ____  _____ _______ _____ ____  _   _  _____
   |  ____| \ | |__   __|  ____|  __ \|  __ \|  __ \|_   _|/ ____|  ____|  / __ \|  __ \__   __|_   _/ __ \| \ | |/ ____|
   | |__  |  \| |  | |  | |__  | |__) | |__) | |__) | | | | (___ | |__    | |  | | |__) | | |    | || |  | |  \| | (___
   |  __| | . ` |  | |  |  __| |  _  /|  ___/|  _  /  | |  \___ \|  __|   | |  | |  ___/  | |    | || |  | | . ` |\___ \
   | |____| |\  |  | |  | |____| | \ \| |    | | \ \ _| |_ ____) | |____  | |__| | |      | |   _| || |__| | |\  |____) |
   |______|_| \_|  |_|  |______|_|  \_\_|    |_|  \_\_____|_____/|______|  \____/|_|      |_|  |_____\____/|_| \_|_____/
  */



  //	Array con le tipologie di enterprise disponibilie
  private _enterpriseTypeSelect =
    [
      {
        key: '1',
        value: 'Scuola'
      },
      {
        key: '2',
        value: 'Comune'
      },
      {
        key: '3',
        value: 'Associazione'
      },
      {
        key: '4',
        value: 'Ditta'
      },
      {
        key: '5',
        value: 'Accomodation'
      }

    ];

  public get enterpriseTypeSelect() {
    return this.sliceAndSortArray(this._enterpriseTypeSelect);
  }

  public getEnterpriseTypeValueByKey(key: any) {
    return this.getValueByKey(this._enterpriseTypeSelect, key);
  }


  //	Array con le tipologie di enterprise disponibilie
  private _enterpriseTypeSubSelect =
    [
      [
        //	Chiave 0
      ],
      [
        //	Chiave 1
        {
          key: '',
          value: ''
        },
        {
          key: '1',
          value: 'Scuola d\'infanzia'
        },
        {
          key: '2',
          value: 'Scuola primaria'
        },
        {
          key: '3',
          value: 'Scuola secondaria I grado'
        },
        {
          key: '4',
          value: 'Scuola secondaria II grado'
        },
        {
          key: '5',
          value: 'Istituto comprensivo'
        },
        {
          key: '6',
          value: 'Università'
        },
      ],
      [
        //	Chiave 2
      ],
      [
        //	Chiave 3
        {
          key: '',
          value: ''
        },
        {
          key: '7',
          value: 'Associazione Italiana Genitori'
        },
        {
          key: '8',
          value: 'Associazione Familari'
        },
        {
          key: '9',
          value: 'Centro Ricerche Teatrali'
        }
      ],
      [
        //	Chiave 4
        {
          key: '',
          value: ''
        },
        {
          key: '10',
          value: 'Pulizie'
        },
        {
          key: '11',
          value: 'Mensa'
        }
      ],
      [
        //	Chiave 5
        {
          key: '',
          value: ''
        },
        {
          key: '12',
          value: 'Albergo'
        },
        {
          key: '13',
          value: 'Bed & Breakfast'
        },
        {
          key: '14',
          value: 'Appartamento'
        },
        {
          key: '15',
          value: 'Affitta Camere'
        },
        {
          key: '16',
          value: 'Casa Educo'
        }
      ]

    ];

  public get enterpriseTypeSubSelect() {
    let arrya = new Array();

    this._enterpriseTypeSubSelect.forEach(element => {

      element.sort(function (a, b) {
        return (a.value.localeCompare(b.value));
      });

      arrya.push(element);
    });

    return arrya.slice();
  }

  public getEnterpriseTypeSubSelectValueByKey(type: any, key: any) {
    return this.getValueByKey(this._enterpriseTypeSubSelect[type], key);
  }






  //	Array con i tipi di indirizzi presenti
  private _enterpriseTypeLegalSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Paritaria'
      },
      {
        key: '2',
        value: 'Privata'
      },
      {
        key: '3',
        value: 'Statale'
      }
    ];

  public get enterpriseTypeLegalSelect() {
    return this.sliceAndSortArray(this._enterpriseTypeLegalSelect);
  }







  //	Array con i tipi di indirizzi presenti
  private _addressEnterpriseSelect =
    [
      {
        key: '1',
        value: 'Office Address'
      },
      {
        key: '2',
        value: 'Postal Address'
      }
    ];

  public get addressEnterpriseSelect() {
    return this.sliceAndSortArray(this._addressEnterpriseSelect);
  }

  public getAddressEnterpriseValueByKey(key: any) {
    return this.getValueByKey(this._addressEnterpriseSelect, key);
  }




  //	Array con le tipologie di contatti
  private _contactEnterpriseSelect =
    [
      {
        key: '1',
        value: 'Email'
      },
      {
        key: '2',
        value: 'Fax'
      },
      {
        key: '3',
        value: 'Emergency'
      },
      {
        key: '4',
        value: 'Home phone'
      },
      {
        key: '5',
        value: 'Mobile phone'
      },
      {
        key: '6',
        value: 'Web address'
      },
      {
        key: '7',
        value: 'Office phone'
      }

    ];

  public get contactEnterpriseSelect() {
    return this.sliceAndSortArray(this._contactEnterpriseSelect);
  }

  public getContactEnterpriseValueByKey(key: any) {
    return this.getValueByKey(this._contactEnterpriseSelect, key);
  }




  //	Array con le tipologie di account pagamenti
  private _paymentEnterpriseSelect =
    [
      {
        key: '1',
        value: 'Bank Account'
      },
      {
        key: '2',
        value: 'Paypal'
      }
    ];

  public get paymentEnterpriseSelect() {
    return this.sliceAndSortArray(this._paymentEnterpriseSelect);
  }

  public getPaymentEnterpriseValueByKey(key: any) {
    return this.getValueByKey(this._paymentEnterpriseSelect, key);
  }




  //	Array con le tipologie di documenti
  private _documentEnterpriseSelect =
    [
      {
        key: '1',
        value: 'Insurance'
      },
      {
        key: '2',
        value: 'Partita Iva'
      }
    ];

  public get documentEnterpriseSelect() {
    return this.sliceAndSortArray(this._documentEnterpriseSelect);
  }

  public getDocumentEnterpriseValueByKey(key: any) {
    return this.getValueByKey(this._documentEnterpriseSelect, key);
  }





  //	Array con le tipologie di Parking per le accomodation
  private _accomodationParkingSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Niente parcheggio'
      },
      {
        key: '2',
        value: 'Parcheggio a pagamento'
      },
      {
        key: '3',
        value: 'Parcheggio libero'
      }
    ];

  public get accomodationParkingSelect() {
    return this.sliceAndSortArray(this._accomodationParkingSelect);
  }

  public getAccomodationParkingValueByKey(key: any) {
    return this.getValueByKey(this._accomodationParkingSelect, key);
  }



















  /*

     _____ _    _  ______          __    ____  _____ _______ _____ ____  _   _  _____
    / ____| |  | |/ __ \ \        / /   / __ \|  __ \__   __|_   _/ __ \| \ | |/ ____|
   | (___ | |__| | |  | \ \  /\  / /   | |  | | |__) | | |    | || |  | |  \| | (___
    \___ \|  __  | |  | |\ \/  \/ /    | |  | |  ___/  | |    | || |  | | . ` |\___ \
    ____) | |  | | |__| | \  /\  /     | |__| | |      | |   _| || |__| | |\  |____) |
   |_____/|_|  |_|\____/   \/  \/       \____/|_|      |_|  |_____\____/|_| \_|_____/


  */








  //	Array con le tipologie di Spettacoli disponibili
  private _showTypeSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'English Day (2017)'
      },
      {
        key: '2',
        value: 'English workshop (2017)'
      },
      {
        key: '3',
        value: 'Musical & Shakespeare Theaching Theatre (2017)'
      },
      {
        key: '4',
        value: 'Theatrino (2017)'
      },
      {
        key: '5',
        value: 'Theatrino Sketches (2017)'
      },
      {
        key: '6',
        value: 'PM&C'
      },
      {
        key: '7',
        value: 'Lettorato'
      },
      {
        key: '8',
        value: 'CLIL'
      },
      {
        key: '9',
        value: 'TEATRINO'
      },
      {
        key: '10',
        value: 'T.i.E - DAY'
      },
      {
        key: '11',
        value: 'T.i.E - DAYS'
      },
      {
        key: '12',
        value: 'STORYTELLING'
      },
      // ATTENZIONE: Questo array viene usato anche come nome del progetto in una data del teatrino e bisogna aggiungere nel backend la voce corrispondente per la generazione del PDF nel file TheatrinoAgreementController.php
      // {
      // 	key: '12',
      // 	value: 'STORYTELLING'
      // }
    ];


  public get showTypeSelect() {
    return this.sliceAndSortArray(this._showTypeSelect);
  }


  public getShowTypeValueByKey(key: any) {
    return this.getValueByKey(this._showTypeSelect, key);
  }






  //	Array con le tipologie di Workshop disponibili
  private _workshopTypeSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Facoltativo da 30 minuti'
      },
      {
        key: '2',
        value: 'Facoltativo da 45 minuti'
      },
      {
        key: '3',
        value: 'Facoltativo da 60 minuti'
      },
      {
        key: '4',
        value: 'Nessuno'
      }
    ];

  public get workshopTypeSelect() {
    return this.sliceAndSortArray(this._workshopTypeSelect);
  }





  //	Array con le tipologie di audience per gli spettacoli
  private _showAudienceSelect =
    [
      {
        key: '0',
        value: 'Scuola d\'infanzia'
      },
      {
        key: '1',
        value: 'Scuola primaria'
      },
      {
        key: '2',
        value: 'Scuola secondaria di I grado'
      },
      {
        key: '3',
        value: 'Scuola secondaria di II grado'
      },
      {
        key: '4',
        value: 'Università'
      }
    ];

  public get showAudienceSelect() {
    return this._showAudienceSelect.slice();
  }

















  /*

    _______ _    _ ______       _______ _____  _____ _   _  ____      ____  _____ _______ _____ ____  _   _  _____
   |__   __| |  | |  ____|   /\|__   __|  __ \|_   _| \ | |/ __ \    / __ \|  __ \__   __|_   _/ __ \| \ | |/ ____|
    | |  | |__| | |__     /  \  | |  | |__) | | | |  \| | |  | |  | |  | | |__) | | |    | || |  | |  \| | (___
    | |  |  __  |  __|   / /\ \ | |  |  _  /  | | | . ` | |  | |  | |  | |  ___/  | |    | || |  | | . ` |\___ \
    | |  | |  | | |____ / ____ \| |  | | \ \ _| |_| |\  | |__| |  | |__| | |      | |   _| || |__| | |\  |____) |
    |_|  |_|  |_|______/_/    \_\_|  |_|  \_\_____|_| \_|\____/    \____/|_|      |_|  |_____\____/|_| \_|_____/



  */





  //	Array con le tipologie di tour per il theatrino
  private _tourTheatrinoType =
    [
      {
        key: '1',
        value: 'Promo'
      },
      {
        key: '2',
        value: 'Prove'
      },
      {
        key: '3',
        value: 'Standard'
      },

    ];

  public get tourTheatrinoTypeSelect() {
    return this.sliceAndSortArray(this._tourTheatrinoType);
  }


  public getTheatrinoTypeValueByKey(key: any) {
    return this.getValueByKey(this._tourTheatrinoType, key);
  }




  //	Array con gli status del singolo tour di un theatrino
  private _theatrinoTourStatus =
    [
      {
        key: '1',
        value: 'Confermato',
        color: {
          primary: '#ffb147',
          secondary: '#ffc77a',
          name: 'orange'
        }
      },
      {
        key: '2',
        value: 'Cancellato',
        color: {
          primary: '#aaaaaa',
          secondary: '#aaaaaa',
          name: 'gray'
        }
      },
      {
        key: '3',
        value: 'Non Confermato',
        color: {
          primary: '#e8e829',
          secondary: '#f4f4b2',
          name: 'yellow'
        }
      },
      {
        key: '4',
        value: 'Convenzione Firmata',
        color: {
          primary: '#0da500',
          secondary: '#b7f4b2',
          name: 'green-jungle'
        }
      },
      {
        key: '5',
        value: 'Convenzione Generata',
        color: {
          primary: '#ffb147',
          secondary: '#ffc77a',
          name: 'orange'
        }
      }
    ];


  public get theatrinoTourStatusSelect() {
    return this.sliceAndSortArray(this._theatrinoTourStatus);
  }

  public getTheatrinoStatusValueByKey(key: any) {
    return this.getValueByKey(this._theatrinoTourStatus, key);
  }

  public getTheatrinoStatusColorByKey(key: any) {

    let found = {
      primary: "#000",
      secondary: "#000",
      name: ""
    };

    this._theatrinoTourStatus.forEach(e => {
      if (e.key == key)
        return found = e.color;
    });

    return found;
  }






  //	Array con le tipologie di pagamenti di un tour del theatrino
  private _paymentTourType =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Gratis'
      },
      {
        key: '2',
        value: 'Fattura'
      },
      {
        key: '3',
        value: 'Ricevuta'
      }
    ];

  public get paymentTourTypeSelect() {
    return this.sliceAndSortArray(this._paymentTourType);
  }

  public getPaymentTourValueByKey(key: any) {
    return this.getValueByKey(this._paymentTourType, key);
  }



  //	Array con le tipologie degli eventi legati al tour di un theatrino
  private _theatrinoTourEvent =
    [
      {
        key: '1',
        value: 'Day Off',
      },
      {
        key: '2',
        value: 'Booking',
      },
      {
        key: '3',
        value: 'Swap Day',
      },
      {
        key: '4',
        value: 'Admin note',
      },
      {
        key: '5',
        value: 'Accomodation',
      }
    ];


  public get theatrinoTourEventSelect() {
    return this.sliceAndSortArray(this._theatrinoTourEvent);
  }

  public getTheatrinoEventValueByKey(key: any) {
    return this.getValueByKey(this._theatrinoTourEvent, key);
  }











  private _primanotaRegisteredViaSelect =
    [
      {
        key: '1',
        value: 'Coupon'
      },
      {
        key: '2',
        value: 'Cash'
      },
      {
        key: '3',
        value: 'Cheque'
      },
      {
        key: '4',
        value: 'Credit Card'
      },
      {
        key: '5',
        value: 'Money Transfer'
      },
      {
        key: '6',
        value: 'Scholarship'
      },
      {
        key: '7',
        value: 'Paypal'
      },
      {
        key: '8',
        value: 'Stripe'
      },
    ];

  public get primanotaRegisteredViaSelect() {
    return this.sliceAndSortArray(this._primanotaRegisteredViaSelect);
  }

  public getPrimanotaRegisteredViaSelect(key: any) {
    return this.getValueByKey(this._primanotaRegisteredViaSelect, key);
  }

  //	Array con gli status dei camp
  private _campStatus =
    [
      {
        key: '1',
        value: 'Confermato',
        color: {
          primary: '#ffb147',
          secondary: '#ffc77a',
          name: 'green-jungle'
        }
      },
      {
        key: '2',
        value: 'Cancellato',
        color: {
          primary: '#aaaaaa',
          secondary: '#aaaaaa',
          name: 'gray'
        }
      },
      {
        key: '3',
        value: 'Non Confermato',
        color: {
          primary: '#e8e829',
          secondary: '#f4f4b2',
          name: 'yellow'
        }
      },
      {
        key: '4',
        value: 'Completato',
        color: {
          primary: '#0da500',
          secondary: '#b7f4b2',
          name: 'blue-soft'
        }
      },
      {
        key: '5',
        value: 'Convenzione Firmata',
        color: {
          primary: '#0da500',
          secondary: '#b7f4b2',
          name: 'green-jungle'
        }
      },
      {
        key: '6',
        value: 'Convenzione Generata',
        color: {
          primary: '#ffb147',
          secondary: '#ffc77a',
          name: 'orange'
        }
      }
    ];


  public get campStatusSelect() {
    return this.sliceAndSortArray(this._campStatus);
  }

  public getCampStatusValueByKey(key: any) {
    return this.getValueByKey(this._campStatus, key);
  }

  public getCampStatusColorByKey(key: any) {

    let found = {
      primary: "#000",
      secondary: "#000",
    };

    this._campStatus.forEach(e => {
      if (e.key == key)
        return found = e.color;
    });

    return found;
  }

  public get getCampStatusValues() {
    let orderedArray = this.sliceAndSortArray(this._campStatus);

    var returnedArray = [];
    orderedArray.forEach((element) => {
      returnedArray.push({ id: element.key, text: element.value });
    });

    return returnedArray;
  }


  //	Array con le tipologie di note del diario
  private _diaryCampSelect =
    [
      {
        key: '1',
        value: 'Nota generica'
      },
      {
        key: '2',
        value: 'Problema'
      },
      {
        key: '3',
        value: 'Preventivo'
      }
    ];

  public get diaryCampSelect() {
    return this.sliceAndSortArray(this._diaryCampSelect);
  }

  public getDiaryCampValueByKey(key: any) {
    return this.getValueByKey(this._diaryCampSelect, key);
  }








  private _diaryCampSubscriberSchoolTypeSelect =
    [
      {
        key: '1',
        value: 'Infanzia'
      },
      {
        key: '2',
        value: 'Primaria'
      },
      {
        key: '3',
        value: 'Secondaria I grado'
      },
      {
        key: '4',
        value: 'Secondaria II grado'
      }
    ];

  public get diaryCampSubscriberSchoolTypeSelect() {
    return this.sliceAndSortArray(this._diaryCampSubscriberSchoolTypeSelect);
  }

  public getDiaryCampSubscriberSchoolTypeValueByKey(key: any) {
    return this.getValueByKey(this._diaryCampSubscriberSchoolTypeSelect, key);
  }


  //	Array con le tipologie di enterprise disponibilie
  private _diaryCampSubscriberSchoolTypeSubSelect =
    [
      // Chiave 0
      [

      ],
      [
        //	Chiave 1
        {
          key: '1',
          value: '1'
        },
      ],
      [
        //	Chiave 2
        {
          key: '1',
          value: '1'
        },
        {
          key: '2',
          value: '2'
        }, {
          key: '3',
          value: '3'
        }, {
          key: '4',
          value: '4'
        }, {
          key: '5',
          value: '5'
        }
      ],
      [
        //	Chiave 3
        {
          key: '1',
          value: '1'
        },
        {
          key: '2',
          value: '2'
        },
        {
          key: '3',
          value: '3'
        }
      ],
      [
        //	Chiave 4
        {
          key: '1',
          value: '1'
        },
        {
          key: '2',
          value: '2'
        },
        {
          key: '3',
          value: '3'
        }
        ,
        {
          key: '4',
          value: '4'
        }
        ,
        {
          key: '5',
          value: '5'
        }
      ]
    ];

  public get diaryCampSubscriberSchoolTypeSubSelect() {
    let arrya = new Array();

    this._diaryCampSubscriberSchoolTypeSubSelect.forEach(element => {

      element.sort(function (a, b) {
        return (a.value.localeCompare(b.value));
      });

      arrya.push(element);
    });

    return arrya.slice();
  }

  public getDiaryCampSubscriberSchoolTypeSubValueByKey(type: any, key: any) {
    return this.getValueByKey(this._diaryCampSubscriberSchoolTypeSubSelect[type], key);
  }





  //IN: Kitty from the office / recharge / show's payment / Kitty from the card

  private _primanotaCausalInSelect =
    [
      {
        key: '1',
        value: 'Kitty from the office'
      },
      {
        key: '2',
        value: 'Recharge'
      },
      {
        key: '3',
        value: "Show's payment"
      },
      {
        key: '4',
        value: 'Kitty from the card'
      },
      {
        key: '5',
        value: 'Enrollment fee'
      },
      {
        key: '6',
        value: 'Course fee'
      },

    ];

  public get primanotaCausalInSelect() {
    return this.sliceAndSortArray(this._primanotaCausalInSelect);
  }

  public getPrimanotaCausalInSelect(key: any) {

    let found = "";

    return this.getValueByKey(this._primanotaCausalInSelect, key);
  }

  private _primanotaCausalOutSelect =
    [
      {
        key: '1',
        value: 'Accomodation'
      },
      {
        key: '2',
        value: 'Petrol'
      },
      {
        key: '3',
        value: 'Transfer'
      },
      {
        key: '4',
        value: 'Food'
      },
      {
        key: '5',
        value: 'Material'
      },
      {
        key: '6',
        value: 'Telephone'
      },
      {
        key: '7',
        value: 'Withdrawal'
      },
      {
        key: '8',
        value: 'Parking'
      },
      {
        key: '9',
        value: 'Other'
      },
      // escursione / spese postali / Foto & Video / Ricarica Telefonica / Rimborsi / Contributo / Compenso / Mensa / Pulizie / Danni
      {
        key: '10',
        value: 'Excursion'
      },
      {
        key: '11',
        value: 'Postage costs'
      },
      {
        key: '12',
        value: 'Photo & Video'
      },
      {
        key: '13',
        value: 'Telephone top-up'
      },
      {
        key: '14',
        value: 'Refunds'
      },
      {
        key: '15',
        value: 'Grant'
      },
      {
        key: '16',
        value: 'Remuneration'
      },
      {
        key: '17',
        value: 'Mensa'
      },
      {
        key: '18',
        value: 'Cleaning'
      },
      {
        key: '19',
        value: 'Damage'
      },
    ];

  //OUT: Accomodation / Petrol / Transfer / Food / Material / Other / Telephone / Withdrawal / Parking

  public get primanotaCausalOutSelect() {
    return this.sliceAndSortArray(this._primanotaCausalOutSelect);
  }

  public getPrimanotaCausalOutSelect(key: any) {

    let found = "";

    return this.getValueByKey(this._primanotaCausalOutSelect, key);
  }



  switchCasualeTypeBasedOnHolderType(holderType, detectedType) {

    if (holderType.indexOf("Person") > 0 || holderType.indexOf("Enterprise") > 0) {
      return detectedType == "in" ? "out" : "in";
    }

    return detectedType;
  }

  // verifica in base al tipo di entità e in base alla scelta di addin o addout quale casuale mostrare
  // questo si verifica perché l'array in-out è corretto per gruppi e camp, mentre per persone ed enterprise è l'opposto
  public getPrimanotaCausalSelect(detectedType, holderType) {

    let typeCasual = this.switchCasualeTypeBasedOnHolderType(holderType, detectedType);

    if (typeCasual == "in")
      return this.primanotaCausalInSelect;

    if (typeCasual == "out")
      return this.primanotaCausalOutSelect;
  }



  //	Array con le seniority dei camp
  private _campSeniority =
    [
      {
        key: '1',
        value: 'Nuova',
      },
      {
        key: '2',
        value: 'Senior',
      }
    ];


  public get campSenioritySelect() {
    return this.sliceAndSortArray(this._campSeniority);
  }

  public getCampSeniorityValueByKey(key: any) {
    return this.getValueByKey(this._campSeniority, key);
  }






  //	Array con le tipologie di un camp
  private _campType =
    [
      {
        key: '1',
        value: 'Educo Camp'
      },
      {
        key: '2',
        value: 'Educo Summer Camps'
      },
      {
        key: '3',
        value: 'International Camps'
      },
      {
        key: '4',
        value: 'Educo Campus'
      },
      {
        key: '5',
        value: 'Little Star Camps'
      },
      {
        key: '6',
        value: 'Gita Scolastica'
      }
    ];

  public get campTypeSelect() {
    return this.sliceAndSortArray(this._campType);
  }

  public getCampTypeValueByKey(key: any) {
    return this.getValueByKey(this._campType, key);
  }




  //	Array con le tipologie di un camp
  private _campStaffType =
    [
      {
        key: '1',
        value: 'Assistant',
        type: 'people'
      },
      {
        key: '2',
        value: 'Assistant volontario',
        type: 'people'
      },
      {
        key: '3',
        value: 'Director',
        type: 'people'
      },
      {
        key: '4',
        value: 'Helper',
        type: 'people'
      },
      {
        key: '5',
        value: 'Helper volontario',
        type: 'people'
      },
      {
        key: '6',
        value: 'Organizzatore',
        type: 'people'
      },
      {
        key: '7',
        value: 'Group Leader',
        type: 'people'
      },
      {
        key: '8',
        value: 'Tutor',
        type: 'people'
      },
      {
        key: '9',
        value: 'Staff Pulizie (people)',
        type: 'people'
      },
      {
        key: '10',
        value: 'Staff Mensa (enterprise)',
        type: 'enterprise'
      },
      {
        key: '11',
        value: 'Staff Pulizie (enterprise)',
        type: 'enterprise'
      },
      {
        key: '12',
        value: 'Educatore',
        type: 'people'
      },
    ];

  public get campStaffTypeSelect() {
    //Remove Tutor from select
    return this._campStaffType.slice().filter(item => item.key !== '8').sort(function (a, b) {
      return a.value.localeCompare(b.value)
    });
  }

  public getCampStaffTypeValueByKey(key: any) {
    return this.getValueByKey(this._campStaffType, key);
  }

  public getCampStaffTypeByKey(key: any) {

    let found = null;

    this._campStaffType.forEach(e => {
      if (e.key == key)
        return found = e;
    });

    return found;
  }





  //	Array con le tipologie di un camp
  private _campStaffDocumentType =
    [
      {
        key: '1',
        value: 'Lettera d\'incarico'
      },
      {
        key: '2',
        value: 'Certificato'
      },
      {
        key: '3',
        value: 'Travel Details Arrivo'
      },
      {
        key: '4',
        value: 'Travel Details Partenza'
      },
      {
        key: '5',
        value: 'Details Prossimo Camp'
      }
    ];

  public get campStaffDocumentTypeSelect() {
    return this.sliceAndSortArray(this._campStaffDocumentType);
  }

  public getCampStaffDocumentTypeValueByKey(key: any) {
    return this.getValueByKey(this._campStaffDocumentType, key);
  }






  //	Array con le tipologie di prodotti del magazzino
  private _warehouseProductType =
    [
      {
        key: '',
        value: ''
      },
      {
        key: 'libro',
        value: 'Libro'
      },
    ];

  public get warehouseProductTypeSelect() {
    return this.sliceAndSortArray(this._warehouseProductType);
  }

  public getWarehouseProductTypeValueByKey(key: any) {
    return this.getValueByKey(this._warehouseProductType, key);
  }







  //	Array con i tag associati ai prodotti del magazzino
  private _warehouseProductTags =
    [
      {
        key: 'camp',
        value: 'Camps'
      },
      {
        key: 'actor',
        value: 'Actors'
      },
    ];

  public get warehouseProductTagsSelect() {
    return this.sliceAndSortArray(this._warehouseProductTags);
  }

  public getWarehouseProductTagsValueByKey(key: any) {
    return this.getValueByKey(this._warehouseProductTags, key);
  }







  //	Array con i modi in cui gli iscritti sono venuti a conoscenza dei corsi di formazione
  private _courseSubscriberMeetUsSelect =
    [
      {
        key: '1',
        value: 'Mailing'
      },
      {
        key: '2',
        value: 'Da un collega'
      },
      {
        key: '3',
        value: 'Sito Web'
      },
      {
        key: '4',
        value: 'Piattaforma Sofia'
      },
      {
        key: '5',
        value: 'Altro'
      }
    ];

  public get courseSubscriberMeetUsSelect() {
    return this.sliceAndSortArray(this._courseSubscriberMeetUsSelect);
  }

  public courseSubscriberMeetUsValueByKey(key: any) {
    return this.getValueByKey(this._courseSubscriberMeetUsSelect, key);
  }



  //	Array con le tipologie di audience per i cordi di aggiornamento
  private _courseScheduleAudienceSelect =
    [
      {
        key: '0',
        value: 'Scuola d\'infanzia'
      },
      {
        key: '1',
        value: 'Scuola primaria'
      },
      {
        key: '2',
        value: 'Scuola secondaria di I grado'
      },
      {
        key: '3',
        value: 'Scuola secondaria di II grado'
      },
      {
        key: '4',
        value: 'Università'
      }
    ];

  public get courseScheduleAudienceSelect() {
    return this._courseScheduleAudienceSelect.slice();
  }






  //	Array con gli status dei camp
  private _courseScheduleStatus =
    [
      {
        key: '1',
        value: 'Confermato',
        color: {
          primary: '#97d492',
          secondary: '#b7f4b2',
          name: 'green-jungle'
        }
      },
      {
        key: '2',
        value: 'Cancellato',
        color: {
          primary: '#d1d1d1',
          secondary: '#e3e3e3',
          name: 'grey-mint'
        }
      },
      {
        key: '3',
        value: 'Non Confermato',
        color: {
          primary: '#ffff8a',
          secondary: '#ffffb0',
          name: 'yellow-lemon'
        }
      },
      {
        key: '4',
        value: 'Completato',
        color: {
          primary: '#ffc18f',
          secondary: '#ffdabd',
          name: 'yellow-casablanca'
        }
      }
    ];


  public get courseScheduleStatusSelect() {
    return this.sliceAndSortArray(this._courseScheduleStatus);
  }

  public getCourseScheduleStatusValueByKey(key: any) {
    return this.getValueByKey(this._courseScheduleStatus, key);
  }

  public getCourseScheduleStatusColorByKey(key: any) {

    let found = {
      primary: "#000",
      secondary: "#000",
    };

    this._courseScheduleStatus.forEach(e => {
      if (e.key == key)
        return found = e.color;
    });

    return found;
  }

  public get getCourseScheduleStatusValues() {
    let orderedArray = this.sliceAndSortArray(this._courseScheduleStatus);

    var returnedArray = [];
    orderedArray.forEach((element) => {
      returnedArray.push({ id: element.key, text: element.value });
    });

    return returnedArray;
  }

  //	Array con le tipologie di note del diario
  private _diaryCourseScheduleSelect =
    [
      {
        key: '1',
        value: 'Nota generica'
      },
      {
        key: '2',
        value: 'Problema'
      },
      {
        key: '3',
        value: 'Preventivo'
      }
    ];

  public get diaryCourseScheduleSelect() {
    return this.sliceAndSortArray(this._diaryCourseScheduleSelect);
  }

  public getDiaryCourseScheduleValueByKey(key: any) {
    return this.getValueByKey(this._diaryCourseScheduleSelect, key);
  }

  private _courseScheduleStaffType =
    [
      {
        key: '1',
        value: 'Helper',
        type: 'people'
      },
      {
        key: "2",
        value: "Group Leader",
        type: "people"
      }
    ];

  public get courseScheduleStaffTypeSelect() {
    //Remove Tutor from select
    return this._courseScheduleStaffType.slice().filter(item => item.key !== '8').sort(function (a, b) {
      return a.value.localeCompare(b.value)
    });
  }

  public getCourseScheduleStaffTypeValueByKey(key: any) {
    return this.getValueByKey(this._courseScheduleStaffType, key);
  }

  public getCourseScheduleStaffTypeByKey(key: any) {

    let found = null;

    this._courseScheduleStaffType.forEach(e => {
      if (e.key == key)
        return found = e;
    });

    return found;
  }



  //	Array con le tipologie di Spettacoli disponibili
  private _courseTypeSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Convegni'
      },
      {
        key: '2',
        value: 'Corsi Gratuiti'
      },
      {
        key: '3',
        value: 'Corsi a pagamento in Italia'
      },
      {
        key: '4',
        value: 'Corsi a pagamento all\'estero'
      },
      {
        key: '5',
        value: 'Corso con Ente'
      },
      {
        key: '6',
        value: 'Corso Online'
      }
    ];

  public get courseTypeSelect() {
    return this.sliceAndSortArray(this._courseTypeSelect);
  }


  public getCourseTypeValueByKey(key: any) {
    return this.getValueByKey(this._courseTypeSelect, key);
  }



  //	Array con le opzioni del tipo di veicolo
  private _vehicleTypeSelect =
    [
      {
        key: '1',
        value: 'Auto'
      },
      {
        key: '2',
        value: 'Moto'
      }
    ];

  public get vehicleTypeSelect() {
    return this.sliceAndSortArray(this._vehicleTypeSelect);
  }

  public getVehicleTypeValueByKey(key: any) {
    return this.getValueByKey(this._vehicleTypeSelect, key);
  }




  //	Array con le opzioni del tipo di cambio di un veicolo
  private _vehicleShiftTypeSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Manuale'
      },
      {
        key: '2',
        value: 'Automatico'
      }
    ];

  public get vehicleShiftTypeSelect() {
    return this.sliceAndSortArray(this._vehicleShiftTypeSelect);
  }

  public getVehicleShiftTypeValueByKey(key: any) {
    return this.getValueByKey(this._vehicleShiftTypeSelect, key);
  }


  //	Array con le tipologie di note del diario
  private _diaryVehicleSelect =
    [
      {
        key: '1',
        value: 'Nota generica'
      },
      {
        key: '2',
        value: 'Problema'
      },
      {
        key: '3',
        value: 'Preventivo'
      }
    ];

  public get diaryVehicleSelect() {
    return this.sliceAndSortArray(this._diaryVehicleSelect);
  }

  public getDiaryVehicleValueByKey(key: any) {
    return this.getValueByKey(this._diaryVehicleSelect, key);
  }



  //	Array con le opzioni del tipo di contratto di un veicolo
  private _vehicleContractTypeSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Prorietà'
      },
      {
        key: '2',
        value: 'Noleggio'
      }
    ];

  public get vehicleContractTypeSelect() {
    return this.sliceAndSortArray(this._vehicleContractTypeSelect);
  }

  public getVehicleContractTypeValueByKey(key: any) {
    return this.getValueByKey(this._vehicleContractTypeSelect, key);
  }



  //	Array con le opzioni del tipo di scadenza di un veicolo
  private _vehicleExpirationTypeSelect =
    [
      {
        key: '1',
        value: 'Garanzia'
      },
      {
        key: '2',
        value: 'Controllo Km'
      },
      {
        key: '3',
        value: 'Scadenza Noleggio'
      },
      {
        key: '4',
        value: 'Assicurazione'
      },
      {
        key: '5',
        value: 'Tagliando'
      },
      {
        key: '6',
        value: 'Bollo'
      },
      {
        key: '7',
        value: 'Revisione'
      }
    ];

  public get vehicleExpirationTypeSelect() {
    return this.sliceAndSortArray(this._vehicleExpirationTypeSelect);
  }

  public getVehicleExpirationTypeValueByKey(key: any) {
    return this.getValueByKey(this._vehicleExpirationTypeSelect, key);
  }


  //	Array con le opzioni del tipo di cambio di un veicolo
  private _vehicleMaterialAssignmentTypeSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Telepass'
      },
      {
        key: '2',
        value: 'Navigatore satellitare'
      }
    ];

  public get vehicleMaterialAssignmentTypeSelect() {
    return this.sliceAndSortArray(this._vehicleMaterialAssignmentTypeSelect);
  }

  public getVehicleMaterialAssignmentTypeSelect(key: any) {
    return this.getValueByKey(this._vehicleMaterialAssignmentTypeSelect, key);
  }



  private _reminderNotificationTypeSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Email'
      }
    ];

  public defaultNotificationAllDayReminder_Time = "08:30";

  public get reminderNotificationTypeSelect() {
    return this.sliceAndSortArray(this._reminderNotificationTypeSelect);
  }

  public getReminderNotificationTypeSelectValueByKey(key: any) {
    return this.getValueByKey(this._reminderNotificationTypeSelect, key);
  }



  public getActorApplicationTourType(key: String) {

    if (key == null || typeof key == "undefined")
      return '';

    switch (key) {
      case 'STORYTELLING':
        return 'Storytelling';
      case 'ACTINGANDWORKSHOPS':
        return 'Acting and Workshops';
      case 'POPMUSICECULTURE':
        return 'Pop Music & Culture';
      default:
        break;
    }
  }


  public getFacilitatorApplicationTourType(key: String) {

    if (key == null || typeof key == "undefined")
      return '';

    switch (key) {
      case 'STATIC':
        return 'Static';
      case 'TOURING':
        return 'Touring';
      case 'NOSORRY':
        return 'No Sorry';
      default:
        break;
    }
  }

  private _tutorApplicationDateOrientationSelect =
    [
      {
        key: 'ORIENTATION1',
        value: 'June 5th - 11th'
      },
      {
        key: 'ORIENTATION2',
        value: 'June 12th - 18th'
      },
      {
        key: 'ORIENTATION3',
        value: 'June 20th - 26th'
      },
      {
        key: 'ORIENTATION4',
        value: 'June 27th - July 3rd'
      },
      {
        key: 'ORIENTATION5',
        value: 'August 22nd - August 28th'
      },
      {
        key: 'ORIENTATION6',
        value: 'June 26th - July 1st'
      },
      {
        key: 'ORIENTATION7',
        value: 'June 4th - 10th'
      },
      {
        key: 'ORIENTATION8',
        value: 'June 11th - 17th'
      },
      {
        key: 'ORIENTATION9',
        value: 'August 20th - 26th'
      },
      {
        key: 'ORIENTATION10',
        value: 'June 18th - 24th'
      },
      {
        key: 'ORIENTATION11',
        value: 'June 25th - 30th'
      },
      {
        key: 'ORIENTATION12',
        value: 'June 2nd - 7th'
      },
      {
        key: 'ORIENTATION13',
        value: 'June 9th - 14th'
      }
    ];

  public get tutorApplicationDateOrientationSelect() {
    return this._tutorApplicationDateOrientationSelect.slice();
  }


  public getTutorApplicationTourType(key: String) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._tutorApplicationDateOrientationSelect, key);
  }



  private _applicationPhasePreferenceSelect =
    [
      {
        key: '',
        value: ''
      },
      {
        key: '1',
        value: 'Maybe'
      },
      {
        key: '2',
        value: 'Yes'
      },
      {
        key: '3',
        value: 'No'
      }
    ];

  public get applicationPhasePreferenceSelect() {
    return this.sliceAndSortArray(this._applicationPhasePreferenceSelect);
  }

  public getapplicationPhasePreferenceSelectValueByKey(key: any) {
    return this.getValueByKey(this._applicationPhasePreferenceSelect, key);
  }





  //	Array con le opzioni del tipo di room per un corso di formazione
  private _courseScheduleRoomTypeSelect =
    [
      {
        key: '1',
        value: 'Twin'
      },
      {
        key: '2',
        value: 'Double'
      },
      {
        key: '3',
        value: 'Single'
      },
      {
        key: '4',
        value: 'Triple'
      },
      {
        key: '6',
        value: 'Quadruple'
      },
      {
        key: '5',
        value: 'Libera'
      }
    ];

  public get courseScheduleRoomTypeSelect() {
    return this.sliceAndSortArray(this._courseScheduleRoomTypeSelect);
  }

  public getCourseScheduleTyoeValueByKey(key: any) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._courseScheduleRoomTypeSelect, key);
  }



  //	Array con le opzioni dei ruoli di default
  private _courseScheduleRoleForSubscribersSelect =
    [
      {
        key: '1',
        value: 'Docente'
      },
      {
        key: '2',
        value: 'Studente'
      }
    ];

  public get courseScheduleRoleForSubscribersSelect() {
    return this.sliceAndSortArray(this._courseScheduleRoleForSubscribersSelect);
  }

  public courseScheduleRoleDefaultForSubscribers() {
    return this._courseScheduleRoleForSubscribersSelect[0].key;
  }

  public getCourseScheduleRoleForSubscribersValueByKey(key: any) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._courseScheduleRoleForSubscribersSelect, key);
  }
}
